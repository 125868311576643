/*Blog Cards Layout*/
@import "../../_globalColor";

.square {
  width: 440px;
  height: 430px;
  background: $textColorDark;
  border-radius: 4px;
  box-shadow: 0px 20px 50px $darkBoxShadow;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-top: 30px;
}

.square:hover {
  box-shadow: 0 20px 40px $lightBoxShadow;
}

.blog-header-text {
  font-size: 56px;
  font-weight: 400;
}

.blogs-pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.pagination-number {
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
}

.pagination-number.active {
  background-color: $buttonColor;
}

.blog-subtitle {
  text-transform: uppercase;
}
.blog-main-div > * {
  flex: 1;
  margin-bottom: 30px;
}
.blog-main-div {
  display: flex;
  margin-top: 30px;
}
.blog-image-div {
  display: flex;
  justify-content: center;
}

.blog-image-div > img {
  max-width: 100%;
  height: auto;
}
.blog-text-div {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 32px;
}
.subTitle {
  color: $subTitle;
}

/* Media Query */
@media (max-width: 1380px) {
  .blog-header-text {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .blog-header-text {
    font-size: 30px;
    text-align: center;
  }
  .blog-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }
}

@media screen and (max-width: 480px) and (min-width: 300px) {
  .blog-text-div {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}
pre code {
  background-color: #eee;
  border: 1px solid #999;
  display: block;
  padding: 20px;
}

.blog-post {
  width: 80%;
  margin: auto;
  background: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.blog-post h1 {
  font-size: 28px;
  color: #333;
}

.blog-post h2 {
  font-size: 24px;
  color: #333;
}

.blog-post p, .blog-post ul {
  font-size: 16px;
  line-height: 1.6;
}

.blog-post pre {
  background: #eee;
  padding: 10px;
  overflow-x: auto;
}

.blog-post code {
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
}

.blog-post a {
  color: #1a0dab;
  text-decoration: none;
}

.blog-post a:hover {
  text-decoration: underline;
}

.blog-post ul {
  list-style-type: disc;
  margin-left: 20px;
}